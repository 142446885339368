<template>
  <v-container class="py-7">
    <div class="heading mb-7">Акции</div>
    <template v-if="sales && sales.length">
      <v-card
        v-for="(sale, i) in sales"
        class="sale mb-7"
        :key="i"
        :to="{ name: 'Sale', params: { id: sale.id } }"
      >
        <v-img :src="sale.photo" />
        <v-card-title>{{ sale.page_name }}</v-card-title>
        <v-card-subtitle v-html="sale.detail_text" />
      </v-card>
    </template>
    <div v-else>В данный момент акции не проводятся.</div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sales",

  computed: {
    ...mapState("sales", { sales: "list" }),
  },

  async created() {
    if (this.sales && this.sales.length) return;
    const { commit, dispatch } = this.$store;
    commit("overlay/update", { active: true });
    await dispatch("sales/loadList");
    commit("overlay/update", { active: false });
  },
};
</script>

<style lang="scss" scoped>
.sale {
  text-align: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;

  .v-card__title {
    justify-content: center;
    word-break: break-word;
  }
}
</style>
